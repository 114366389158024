/* eslint-disable no-undef */
import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2'
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'
import axios from 'axios'
import VueAxios from 'vue-axios'

import vco from 'v-click-outside'

import router from './router'
import store from '@/state/store'
import i18n from './i18n'

import '@/assets/scss/app.scss'

import { initFirebaseBackend } from './helpers/firebase/authUtils'

import { configureFakeBackend } from './helpers/fakebackend/fake-backend'

// setup vee validation ------------
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { email, min, max } from 'vee-validate/dist/rules'
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1,
    }
  },
  computesRequired: true,
})
extend('email', email)
extend('min', min)
extend('max', max)
// end setup vee validation ------------
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
Vue.component('VSelect', vSelect)

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID,
}

if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
  initFirebaseBackend(firebaseConfig)
} else {
  configureFakeBackend()
}

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDp8Trf5wSVyYi-V8cxkPt4EE0aPy_fVBw',
    libraries: 'places',
  },
  installComponents: true,
})
Vue.component('apexchart', VueApexCharts)

// css animate
require('vue2-animate/dist/vue2-animate.css')

// vue mutiselect
import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)

// vue moment
var moment = require('moment')
// require('moment/locale/th')
 
Vue.use(require('vue-moment'), {
  moment,
})

// vue openlayers
import VueLayers from 'vuelayers'
import 'vuelayers/lib/style.css'
Vue.use(VueLayers)

import { IconStyle } from 'vuelayers'
Vue.use(IconStyle)
import * as ol from 'ol'
Vue.use(ol)

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import money from 'v-money'
// register directive v-money and component <money>
Vue.use(money, { precision: 2 })

import VuetifyMoney from 'vuetify-money'
Vue.use(VuetifyMoney)

// import BlockUI from 'vue-blockui'
// Vue.use(BlockUI)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
