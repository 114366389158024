import store from '@/state/store'

export default [
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/backhaul/account/auth'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          next()
        }
      },
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/backhaul/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: () => import('../views/backhaul/account/register'),
  //   meta: {
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters['auth/loggedIn']) {
  //         // Redirect to the home page instead
  //         next({ name: 'home' })
  //       } else {
  //         next()
  //       }
  //     },
  //   },
  // },
  // {
  //   path: '/forgot-password',
  //   name: 'Forgot-password',
  //   component: () => import('../views/backhaul/account/forgot-password'),
  //   meta: {
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters['auth/loggedIn']) {
  //         // Redirect to the home page instead
  //         next({ name: 'home' })
  //       } else {
  //         next()
  //       }
  //     },
  //   },
  // },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // eslint-disable-next-line no-undef
        if (process.env.VUE_APP_DEFAULT_AUTH === 'firebase') {
          store.dispatch('auth/logOut')
        } else {
          store.dispatch('authfack/logout')
        }
        let authRequiredOnPreviousRoute = ''
        let logout_redirect = localStorage.getItem('logout_redirect_path')
        if(logout_redirect != '' && logout_redirect != 'null' && logout_redirect != null && logout_redirect != undefined) {
          localStorage.clear()
          location.replace(logout_redirect + '/login')
        } else {
          authRequiredOnPreviousRoute = routeFrom.matched.some(route => route.push('/login'))
          // Navigate back to previous page, or home as a fallback
          next(authRequiredOnPreviousRoute ? { name: 'index' } : { ...routeFrom })
        }
      },
    },
  },
  {
    path: '/',
    name: 'main_menu',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        var user = JSON.parse(localStorage.getItem('users'))
        if (user[0].register_flag == '1') {
          if (user[0].member_class == '2') {
            next({ name: 'display_carrier_plans' })
          } else {
            next({ name: 'display_plans' })
          }
        } else {
          next({ name: 'register_index' })
          // next()
        }
      },
    },
    component: () => import('../views/backhaul/main_menu'),
  },
  // ----------- register part -----------
  {
    path: '/register/index',
    name: 'register_index',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        var user = JSON.parse(localStorage.getItem('users'))
        if (user[0].register_flag == '1') {
          if (user[0].member_class == '2') {
            next({ name: 'display_carrier_plans' })
          } else {
            next({ name: 'display_plans' })
          }
        } else {
          next()
        }
      },
    },
    component: () => import('../views/backhaul/register/index'),
  },
  {
    path: '/register/goods_owner',
    name: 'register_goods',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        var user = JSON.parse(localStorage.getItem('users'))
        if (user[0].register_flag == '0') {
          next()
        } else {
          next({ name: 'display_plans' })
          // next()
        }
      },
    },
    component: () => import('../views/backhaul/register/goods_owner/register_owner'),
  },
  {
    path: '/register/transporter',
    name: 'register_transporter',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        var user = JSON.parse(localStorage.getItem('users'))
        if (user[0].register_flag == '0') {
          next()
        } else {
          next({ name: 'display_carrier_plans' })
          // next()
        }
      },
    },
    component: () => import('../views/backhaul/register/transporter/register_owner'),
  },
  {
    path: '/register/goods_and_tran',
    name: 'register_goods_and_tran',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        var user = JSON.parse(localStorage.getItem('users'))
        if (user[0].register_flag == '0') {
          next()
        } else {
          next({ name: 'display_carrier_plans' })
          // next()
        }
      },
    },
    component: () => import('../views/backhaul/register/goods_and_transporter/register_owner'),
  },
  // ----------- End transportation plan part -----------

  // ----------- transportation plan part -----------
  {
    path: '/transportation_plan/create',
    name: 'create_tran_plan',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        var user = JSON.parse(localStorage.getItem('users'))
        if (user[0].register_flag == '1') {
          next()
        } else {
          next({ name: 'register_index' })
          // next()
        }
      },
    },
    component: () => import('../views/backhaul/transportation_plan/create'),
  },
  {
    path: '/transportation_plan/plans_display',
    name: 'display_plans',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        var user = JSON.parse(localStorage.getItem('users'))
        if (user[0].register_flag == '1') {
          next()
        } else {
          next({ name: 'register_index' })
          // next()
        }
      },
    },
    component: () => import('../views/backhaul/transportation_plan/plans_display'),
  },
  {
    path: '/transportation_plan/tracking',
    name: 'tracking_goods',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        var user = JSON.parse(localStorage.getItem('users'))
        if (user[0].register_flag == '1') {
          next()
        } else {
          next({ name: 'register_index' })
          // next()
        }
      },
    },
    component: () => import('../views/backhaul/transportation_plan/tracking'),
  },
  {
    path: '/transportation_plan/history',
    name: 'history_plan',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        var user = JSON.parse(localStorage.getItem('users'))
        if (user[0].register_flag == '1') {
          next()
        } else {
          next({ name: 'register_index' })
          // next()
        }
      },
    },
    component: () => import('../views/backhaul/transportation_plan/history'),
  },
  // ----------- End transportation plan part -----------

  // ----------- carrier part -----------
  {
    path: '/carrier/create',
    name: 'create_carrier_plan',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        var user = JSON.parse(localStorage.getItem('users'))
        if (user[0].register_flag == '1') {
          next()
        } else {
          next({ name: 'register_index' })
          // next()
        }
      },
    },
    component: () => import('../views/backhaul/carrier/create'),
  },
  {
    path: '/carrier/plans_display',
    name: 'display_carrier_plans',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        var user = JSON.parse(localStorage.getItem('users'))
        if (user[0].register_flag == '1') {
          next()
        } else {
          next({ name: 'register_index' })
          // next()
        }
      },
    },
    component: () => import('../views/backhaul/carrier/plans_display'),
  },
  {
    path: '/carrier/tracking',
    name: 'tracking',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        var user = JSON.parse(localStorage.getItem('users'))
        if (user[0].register_flag == '1') {
          next()
        } else {
          next({ name: 'register_index' })
          // next()
        }
      },
    },
    component: () => import('../views/backhaul/carrier/tracking'),
  },
  // ----------- End carrier part -----------
  { 
    path: '/carrier/history',
    name: 'history',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        var user = JSON.parse(localStorage.getItem('users'))
        if (user[0].register_flag == '1') {
          next()
        } else {
          next({ name: 'register_index' })
          // next()
        }
      },
    },
    component: () => import('../views/backhaul/carrier/history'),
  },
  // ----------- End carrier part ----------- 

  // ----------- Profile part -----------
  {
    path: '/profile/personal_profile',
    name: 'personal_profile',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        var user = JSON.parse(localStorage.getItem('users'))
        if (user[0].register_flag == '1') {
          next()
        } else {
          next({ name: 'register_index' })
          // next()
        }
      },
    },
    component: () => import('../views/backhaul/profile/personal_profile'),
  },
  {
    path: '/profile/truck',
    name: 'truck',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        var user = JSON.parse(localStorage.getItem('users'))
        if (user[0].register_flag == '1') {
          next()
        } else {
          next({ name: 'register_index' })
          // next()
        }
      },
    },
    component: () => import('../views/backhaul/profile/truck'),
  },
  {
    path: '/profile/settings',
    name: 'settings',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        var user = JSON.parse(localStorage.getItem('users'))
        if (user[0].register_flag == '1') {
          next()
        } else {
          next({ name: 'register_index' })
          // next()
        }
      },
    },
    component: () => import('../views/backhaul/profile/settings'),
  },
  // ----------- End Profile part -----------

  // ----------- Profile part -----------
  {
    path: '/notification/all_notification',
    name: 'all_notification',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        var user = JSON.parse(localStorage.getItem('users'))
        if (user[0].register_flag == '1') {
          next()
        } else {
          next({ name: 'register_index' })
          // next()
        }
      },
    },
    component: () => import('../views/backhaul/notification/all_notification'),
  },
  // ----------- End Profile part -----------

  {
    path: '/blank_page',
    name: 'blank_page',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/backhaul/blank_page'),
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/tms-pages/contact/index'),
  },
  {
    path: '/transportation_plan/search',
    name: 'carrier_search',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/backhaul/carrier/search'),
  },
  {
    path: '/carrier/search',
    name: 'goods_search',
    meta: {
      authRequired: true,
    },
    component: () => import('../views/backhaul/transportation_plan/search'),
  },
]
